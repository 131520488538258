/* src/pages/NoPermission.css */
.no-permission-container {
    max-width: 700px;
    margin: 100px auto;
    padding: 20px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .no-permission-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .no-permission-container p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .back-button {
    padding: 10px 20px;
    background-color: #ff4d4d; /* 빨간색 톤 예시 */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .back-button:hover {
    background-color: #d14040;
  }