/* client/src/components/AdminStatic.css */

.admin-static-container {
  padding: 20px;
}

.top-controls {
  margin-bottom: 10px;
}

.column-checks {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

input {
  width: auto;
}

.check-box {
  display: flex;
  align-items: center;
  /* 추가적인 스타일 가능 */
}
.chart-wrapper {
  width: 100%;
  height: 300px;
  margin: 20px 0;
  /* 가로 스크롤 등 필요하면 overflow-x 설정 가능 */
}

.simple-table {
  border-collapse: collapse;
  margin-top: 20px;
  width: 100%;
}

.simple-table th,
.simple-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: right;
  white-space: nowrap;
}
.simple-table th {
  background-color: #f7f7f7;
  text-align: center;
}
.simple-table td:first-child {
  text-align: center;
}
