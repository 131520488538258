.admin-users-container {
  padding: 20px;
}

.admin-users-table-wrapper {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* iOS 부드러운 스크롤 */
}

.admin-users-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.admin-users-table th,
.admin-users-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;

  white-space: nowrap;
}
.admin-users-table th {
  background-color: #f0f0f0;
}

.add-user-btn {
  padding: 8px 12px;
  cursor: pointer;
}

.reset-btn {
  padding: 4px 8px;
  cursor: pointer;
  background-color: #ffc;
}
