.container {
  max-width: 700px; /* 모바일 대응 */
  margin: 0 auto;
  padding: 20px;
}

.store-nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.report-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 20%;
  padding: 10px 15px;
  font-size: 14px;
  color: #000000;
  background-color: #ffd5c6; /* 코럴 색상 예시 */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.second-form {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
  gap: 30px;
}

.one-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}

label {
  font-weight: bold;
  margin-top: 10px;
}

input {
  padding: 8px;
  font-size: 14px;
}

.one-form > input {
  text-align: center;
  font-size: 1.1em;
  width: 80%;
  border-radius: 20px;
  border: 1px solid lightblue;
}

.one-form > label {
  margin-bottom: 10px;
  color: rgb(143, 143, 143);
}

.button-row {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.submit-button {
  width: 30%;
  height: 50px;
  border-radius: 20px;
  border: none;
  background-color: rgb(82, 131, 238);
  font-size: 1.3em;
  color: rgb(255, 255, 255);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.submit-button:hover {
  background-color: rgb(38, 102, 240);
}

.buttonDiv {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.user-info {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 50px;
}
