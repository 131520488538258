.firstcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

/* 모바일 대응을 위해 최대 폭 700px으로 설정하고, 가운데 정렬 */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 70vh;
  margin: 50px; /* 화면 가운데 정렬 & 상단 여백 */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

/* 제목 스타일 */
.login-container h2 {
  margin-bottom: 70px;
}

/* 라벨 + 인풋을 수직으로 묶어주는 그룹 */
.input-group {
  display: flex;
  flex-direction: column;
  align-items: center; /* 라벨, 인풋 왼쪽 정렬 */
  justify-content: center;
  margin: 10px auto;
  width: 80%; /* 중앙에 오는 폭 조절 */
}

/* 라벨 */
.input-group label {
  margin-bottom: 5px;
  font-weight: 600;
}

/* 인풋 */
input {
  padding: 10px;
  width: 60%;
  box-sizing: border-box;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}

/* 로그인 버튼 스타일 */
.login-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ff7f50; /* 코럴 색상 예시 */
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 48%;
}

/* 호버 시 색상 살짝 어둡게 */
.login-button:hover {
  background-color: #ed6f3c;
}

/* 회원가입 링크 컨테이너 */
.register-link {
  margin-top: 20px;
  font-size: 14px;
  color: #555;
}

/* 회원가입 링크 자체 */
.register-link a {
  color: #ff7f50;
  text-decoration: none;
  font-weight: bold;
}

.register-link a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .input-group {
    width: 90%;
  }
  input {
    width: 100%;
  }
  .login-button {
    width: 90%;
  }
}
