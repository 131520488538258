/* client/src/pages/AdminSearch.css */

.admin-search-container {
  padding: 20px;
}

/* 검색 폼 */
.search-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.date-inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: small;
  width: 80%;
}
.date-inputs > label {
  width: 20%;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
}

.quick-buttons {
  display: flex;
  justify-content: center;
  width: 60%;
  height: 10%;
  gap: 10px;
}
.quick-buttons > button {
  width: 30%;
  font-size: 20px;
  font-weight: bold;
  background-color: #ccc;
  border-radius: 10px;
}

.admin-result-table {
  width: 100%;
  border-collapse: collapse;
}

.admin-result-table th,
.admin-result-table td {
  border: 1px solid #ccc;
  padding: 8px 12px;
  text-align: center;

  white-space: nowrap;
}
.recent {
  width: 20%;
}
.noinput {
  width: 15%;
}

.admin-result-table th {
  background-color: #f5f5f5;
}

.storename {
  cursor: pointer;
}

/* 미입력 날짜 버튼 */
.missing-date-btn {
  margin-right: 8px;
  background-color: #ffdddd;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
}

.missing-date-btn:hover {
  background-color: #ffcccc;
}

@media screen and (max-width: 730px) {
  .quick-buttons {
    width: 80%;
    height: 30px;
  }
  .date-inputs {
    width: 100%;
  }
  .date-inputs > label {
    font-size: smaller;
  }
  .date-inputs > input {
    font-size: smaller;
  }
  .quick-buttons > button {
    font-size: small;
    white-space: nowrap;
  }
}
