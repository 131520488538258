/* ModalForm.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명 검은 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: #fff; /* 흰색 팝업창 */
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
  max-width: 80%;
  max-height: 80vh;
  /* 높이 등은 필요에 맞게 조정 */
  overflow-y: auto;
  position: relative; /* close버튼 배치 위해 */
  -webkit-overflow-scrolling: touch;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
