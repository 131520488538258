.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
  position: relative;
}

.modal-content h3 {
  margin-top: 0;
}

.modal-content label {
  display: block;
  margin-top: 8px;
}

.modal-content input {
  width: 100%;
  margin-bottom: 8px;
  padding: 6px;
  box-sizing: border-box;
}

.button-row {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 10px;
}
