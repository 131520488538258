.main-container {
    max-width: 700px; /* 모바일 대응 700px */
    margin: 0 auto;
    text-align: center;
    padding: 20px;
  }
  /* 상단 헤더 (로고, 타이틀, 로그아웃 버튼 등) */
.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: right;
  margin-bottom: 20px;
}



/* 오른쪽 영역: 로그아웃 버튼 */
.right-area {
  display: flex;
  width:100%;
  align-items: center;
  justify-content: right;
}

.user-name {
  font-weight: bold;
  font-size: 20px;
  margin-right: 20px;
  color: #28698f;

}

.logout-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
  padding: 10px 15px;
  font-size: 14px;
  color: #000000;
  background-color: #ffd5c6; /* 코럴 색상 예시 */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.logout-button:hover {
  background-color: #f7c2ad;
  
}

  
  .store-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .store-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    padding: 10px;
    border-radius: 8px;
    color: #000000;
    text-decoration: none;
    font-weight: bold;
    background-color: #888;
    transition: background-color 0.3s;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  }
  
  .store-button:hover {
    background-color: #555;
  }
  
  /* 매장별 버튼은 색상 다르게 */
  .dessert39 {
    background-color: #ff7f50;
  }

  .pcroom1 {
    background-color: #fdec4e;
  }

  .pcroom2 {
    background-color: #adff50;
  }

  .seoga {
    background-color: #50bfff;
  }

  .gjtteok {
    background-color: #3430ff;
  }

  .bodram1 {
    background-color: #b330ff;
  }

  .photoism {
    background-color: #ff30d2;
    margin-bottom: 100px;
  }

  .logo {
    width: 100%;
    height: auto;
  }

  /* 하단 GIF 영역 */
.gif-container {
  margin-top: 20px;
  text-align: center;
}

.bottom-gif {
  max-width: 100%;
  height: auto;
  /* 필요하면 크기 조절 */
}
