/* ProgressBar.css */
.sales-progress-container {
  width: 100%;
  margin: 20px auto;
  font-size: 14px;
}
.progress-text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}
.target-amount {
  font-weight: bold;
}

.stacked-bar {
  width: 100%;
  height: 20px;
  border: 2px solid #80c564; /* 초록색 테두리 */
  border-radius: 20px;
  display: flex;
  overflow: hidden;
  background-color: #fff;
  position: relative;
}
.bar {
  height: 100%;
}
.bar.last-month {
  background-color: #ccc; /* 회색 */
}
.bar.this-month {
  background-color: #ffc84c; /* 노랑 */
  position: absolute;
  left: 0;
}
