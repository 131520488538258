.calendar-container {
  width: 100%; /* 원하는 너비 */
  margin: 0 auto;
  text-align: center;
}

.calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.calendar-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: #f0f0f0;
  font-weight: bold;
}

.weekday {
  padding: 5px;
  border: 1px solid #ddd;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* 
       대략 5~6행이 필요할 수 있으므로
       auto-rows: 60px; or something 
    */
  grid-auto-rows: 60px;
  gap: 1px;
}

.calendar-cell {
  border: 1px solid #ddd;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.calendar-cell.empty {
  background-color: #eee; /* 이전달 빈칸 */
  cursor: default;
}

.date-num {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  margin: 2px;
}

/* 입력된 날: 초록색 */
.entered {
  background-color: #ccffcc; /* 연한초록 */
}

/* 입력안된 날: 빨간색(연한 핑크) */
.not-entered {
  background-color: #ffcccc;
}

.today-cell {
  border: 4px solid red; /* 오늘 날짜 칸 테두리 빨강 */
}

.totalsales {
  position: absolute;
  bottom: 2px;
  right: 2px;
  font-size: 12px;
  color: #333;
}

@media screen and (max-width: 768px) {
  .totalsales {
    font-size: xx-small;
  }
}
