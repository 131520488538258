/* client/src/components/Navbar.css */

/* 공통 스타일 */
.admin-navbar {
  width: 100%;
  background-color: #222;
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column; /* 모바일에서 위 logo, 아래 hamburger */
}

/* 데스크톱 용 */
.navbar-desktop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

/* 로고 or 제목 */
.navbar-logo a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
}

/* 데스크톱 메뉴 */
.navbar-menu {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}
.navbar-menu li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.2s ease-in-out;
}
.navbar-menu li a:hover {
  color: #ffcc00; /* 예: hover 색상 */
}

/* 모바일 용 */
.navbar-mobile {
  display: none; /* 기본은 데스크톱에서 안 보임 */
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

/* 햄버거 버튼 */
.hamburger-btn {
  display: flex;
  flex-direction: column;
  background: none;
  border: none;
  gap: 4px;
  cursor: pointer;
}
.hamburger-btn .bar {
  width: 24px;
  height: 2px;
  background-color: #fff;
}

/* 모바일 슬라이드 메뉴(사이드 바) */
.mobile-menu-slide {
  position: fixed;
  top: 0;
  left: -30%; /* 숨긴 상태 */
  width: 30%;
  height: 100%;
  background-color: #333;
  transition: left 0.3s ease;
  padding: 20px;
  box-sizing: border-box;
  z-index: 999;
}
.mobile-menu-slide.open {
  left: 0; /* 열림 */
}

.close-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  float: right;
  margin-bottom: 10px;
}

.mobile-menu-list {
  list-style: none;
  padding: 0;
  margin-top: 50px;
}
.mobile-menu-list li {
  margin: 15px 0;
}
.mobile-menu-list li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

/* 반응형 미디어 쿼리 */
@media (max-width: 768px) {
  /* 데스크톱 메뉴 숨기고, 모바일 메뉴 보이기 */
  .navbar-desktop {
    display: none;
  }
  .navbar-mobile {
    display: flex;
  }
}
